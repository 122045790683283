import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	closeButton: {
		height: 14,
		width: 14,
	},
	closeButtonXMark: {
		height: 20,
		width: 20,
	},
	visible: {
		overflow: 'visible',
	},
});
